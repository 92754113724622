






















































import Vue from 'vue'
import QRCode from 'qrcode'
import { saveAs as saveToFile } from 'file-saver'
import { compressToBase64 } from 'lz-string'
import { Component } from 'vue-property-decorator'

@Component
export default class SetEncoderDialog extends Vue {
  public dialog: boolean = false
  public encodedSet: string = ''
  public generatedQR: string = ''
  public generationError: string = ''
  public generationFailed: boolean = false
  public isGenerating: boolean = false
  public qrDialog: boolean = false
  public shareError: string = ''
  public shareFailed: boolean = false

  public created() {
    this.$bus.$on('show-set-encoder-dialog', () => {
      this.reset()
      this.dialog = true
    })
  }

  public reset() {
    this.encodedSet = ''
    this.generatedQR = ''
    this.generationError = ''
    this.generationFailed = false
    this.isGenerating = false
    this.qrDialog = false
    this.shareError = ''
    this.dialog = false
  }

  public async createQRFile(): Promise<File> {
    this.isGenerating = true
    const blob = await (await fetch(this.generatedQR)).blob()
    const file = new File([blob], `${this.encodedSet.replace(/ /g, '')}.png`, {
      type: 'image/png',
    })

    return new Promise((resolve) => {
      this.isGenerating = false
      resolve(file)
    })
  }

  public async share() {
    const data = {
      files: [ await this.createQRFile() ],
      title: this.encodedSet,
      text: `Calculate your average for ${this.encodedSet}`,
      url: 'https://calc.dantis.me',
    }

    if (navigator.canShare(data)) {
      navigator.share(data)
        .catch((err) => {
          this.shareError = err
          this.shareFailed = true
        })
    } else {
      this.shareError = 'Browser does not support file sharing'
      this.shareFailed = true
    }
  }

  public async saveToDisk() {
    const file = await this.createQRFile()
    saveToFile(file, file.name)
  }

  public async encode(set: string) {
    // Show dialog, loading
    this.isGenerating = true
    this.qrDialog = true

    // Get subjects
    const subjectSet = this.$store.getters.customSet(set)
    if (subjectSet.subjects.length) {
      try {
        // Create QR code
        this.generatedQR = await QRCode.toDataURL(compressToBase64(JSON.stringify(subjectSet)))
        this.isGenerating = false
        this.encodedSet = set
      } catch (err) {
        this.generationError = err
        this.generationFailed = true
      }
    } else {
      this.generationError = `No subjects exist for ${set}.`
      this.generationFailed = true
    }
  }

  get canShare(): boolean {
    return navigator.share !== undefined && navigator.canShare !== undefined
  }

  get sets(): string[] {
    return this.$store.getters.customSets
  }
}
